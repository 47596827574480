/*------------------------------------------------------------------
[Master Stylesheet]

Project:	OnePager - Responsive one page multipurpose HTML Template
Version:	1.0.0
Assigned to:	Themeforest
Primary use:	Advocate, Attorney, Consultant, Consulting, Corporate, Government, Justice, Law, Lawyer, political
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[Table of contents]

1 Fonts
2 Body
3 Common Css
	3.1 Reset Style
	3.2 Form Inputs
	3.3 Button Style
	3.4 Search Bar
	3.5 Padding Style
	3.6 Margin Style
4 Social Icon / ul.social-icon
5 Header Style
	5.1 Top Header / .top-bar
	5.2 Navbar / a.navbar-brand
6 Banner & Slider / .banner-section
7 Theme Heading / .template-heading
8 Services Style / #services
9 About Us / .about-us-section
10 Full Banner Section / .full_section
11 Team Section / .team_slider
12 How Help / .why_choose_us
13 Testimonial Section / .testimonial-slider
14 Gallery Section / .gallery_image
15 Facts Section / .our-facts
16 Pricing Plane / .pricing-detail
17 Location Map / .location_map
18 Our Blog / .blog
19 Page Style 
	19.1 Owl nav Style / .carousel-style-1
	19.2 Preloader Style / .preloader
	19.3 Search Style / .serach-footer
20 Contact Form / .template-form 
21 Footer Style / footer
-------------------------------------------------------------------*/


@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
/*
==========================
2 Body
==========================
*/


html {
  scroll-behavior: smooth;
}
body {
    font-family: var(--fontFamily);
    font-size: 16px;
    color: #626161;
    line-height: 1.6em;
    background: #ffffff;
    -webkit-font-smoothing: antialiased;
    max-width: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
}
body.dark {
    background-color: #1B1B1B;
}
/*
==========================
3 Common Css
==========================
*/
/* 3.1 Reset Style */

img {
    max-width: 100%;
    float: left;
}
a {
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
    color: inherit;
}
a:hover,
a:focus,
a:visited {
    text-decoration: none;
    outline: none;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
    color: inherit;
}
p,
em {
    font-family: var(--fontFamily);
    font-size: 16px;
    line-height: 1.6em;
    color: #333333;
}
body.dark p,
body.dark em {
    color: #ccc;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    position: relative;
    font-weight: 600;
    margin: 0px;
    background: none;
    line-height: 1.6em;
    font-family: var(--fontFamily);
    color: #000;
    clear: both;
    text-transform: uppercase;
}
body.dark h1,
body.dark h2,
body.dark h3,
body.dark h4,
body.dark h5,
body.dark h6 {
    color: #fff;
}
figure {
    display: inline-block;
    width: 100%;
}
.s-font,
.template-heading h2,
.banner-title,
.facts h3 {
    font-family: var(--fontFamily);
}
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
ul li a {
    display: inline-block;
}
.clear {
    clear: both;
}
.parallax-style {
    background-attachment: fixed;
    height: 100%;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
}
.grey_bg {
    background-color: #eee;
}
body.dark .grey_bg {
    background-color: #131212;
}
.colorW {
    color: #fff;
}
/* 3.2 Form Inputs */

input[type=text],
input[type=tel],
input[type=email],
input[type=search],
input[type=password],
input[type=number],
select {
    padding: 5px 20px;
    border: 0;
    display: inline-block;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.12);
    outline: none;
    height: 40px;
    font-size: 13px;
    color: #000;
}
textarea {
    padding: 5px 20px;
    border: 0;
    display: inline-block;
    float: left;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.12);
    outline: none;
    font-size: 13px;
    color: #000;
}
input[type=radio] {
    position: absolute;
    visibility: hidden;
}
input[type=checkbox] {
    position: absolute;
    visibility: hidden;
}
.box-check:before {
    display: block;
    position: absolute;
    content: "\f00c";
    border-radius: 0;
    top: 0;
    left: 1px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
    font: normal normal normal 11px/1 FontAwesome;
    opacity: 0;
}
.box-check {
    display: block;
    position: absolute;
    border: 2px solid;
    border-radius: 0;
    height: 15px;
    width: 15px;
    top: 17px;
    margin-top: -10px;
    left: 0;
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
}
.check {
    border: 5px solid #FFFFFF;
}
.check::before {
    display: block;
    position: absolute;
    content: '';
    height: 10px;
    width: 10px;
    top: 4px;
    left: 4px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
    visibility: hidden;
}
.check {
    display: block;
    position: absolute;
    border: 2px solid;
    height: 20px;
    width: 20px;
    top: 50%;
    margin-top: -10px;
    left: 15px;
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
}
input[type=radio]:checked ~ .check::before {
    visibility: visible;
}
input[type=radio]:checked ~ label {
    color: #2e2e2e;
}
input[type=checkbox]:checked ~ .box-check {
    border: 2px solid;
}
input[type=checkbox]:checked ~ label {
    color: #2e2e2e;
}
input[type=checkbox]:checked ~ .box-check:before {
    opacity: 1;
}
ul.list label.radio-label,
label.radio-label {
    display: block;
    position: relative;
    font-size: 16px;
    padding: 5px 0px 0px 15px;
    margin: 0 auto;
    height: 30px;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
    text-transform: capitalize;
}
label {
    display: inline-block;
    font-weight: 400;
    margin: 0 0 5px 0px;
    color: #777;
}
input::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #777;
}
input::-moz-placeholder,
select::-moz-placeholder,
textarea::-moz-placeholder {
    color: #777;
}
input:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #777;
}
input:-moz-placeholder,
select:-moz-placeholder,
textarea:-moz-placeholder {
    color: #777;
}
section {
    position: relative;
    width: 100%;
    float: left;
}
/* 3.3 Button Style */

span.itg-button {
    font-size: 16px;
    padding: 15px 30px;
    display: inline-block;
    line-height: 1.2em;
    border: 0;
    color: #fff;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
    border: 0;
    text-transform: uppercase;
    background-color: #333333;
    border: 1px solid #333333;
    font-family: var(--fontFamily);
    cursor: pointer;
}
.itg-button.active {
    color: #fff;
    border: none;
}
.itg-button:hover {
    color: #fff;
}
/* 3.4 Search Bar */

.search_bar {
    position: relative;
    width: 100%;
    float: left;
}
.search_bar .itg-button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    outline: none;
}
/* 3.5 Padding Style */

.pad0 {
    padding: 0px;
}
.pad5 {
    padding: 5px;
}
.pad10 {
    padding: 10px;
}
.pad15 {
    padding: 15px;
}
.pad20 {
    padding: 20px;
}
.pad30 {
    padding: 30px;
}
.pad40 {
    padding: 40px;
}
.pad50 {
    padding: 50px;
}
.pad60 {
    padding: 60px;
}
.pad70 {
    padding: 70px;
}
.pad80 {
    padding: 80px;
}
.pad90 {
    padding: 90px;
}
.pad100 {
    padding: 100px;
}
.padTB0 {
    padding: 0px 0;
}
.padTB5 {
    padding: 5px 0;
}
.padTB10 {
    padding: 10px 0;
}
.padTB15 {
    padding: 15px 0;
}
.padTB20 {
    padding: 20px 0;
}
.padTB30 {
    padding: 30px 0;
}
.padTB40 {
    padding: 40px 0;
}
.padTB50 {
    padding: 50px 0;
}
.padTB60 {
    padding: 60px 0;
}
.padTB70 {
    padding: 70px 0;
}
.padTB80 {
    padding: 80px 0;
}
.padTB90 {
    padding: 90px 0;
}
.padTB100 {
    padding: 100px 0;
}
.padT0 {
    padding-top: 0px;
}
.padT5 {
    padding-top: 5px;
}
.padT10 {
    padding-top: 10px;
}
.padT15 {
    padding-top: 15px;
}
.padT20 {
    padding-top: 20px;
}
.padT30 {
    padding-top: 30px;
}
.padT40 {
    padding-top: 40px;
}
.padT50 {
    padding-top: 50px;
}
.padT60 {
    padding-top: 60px;
}
.padT70 {
    padding-top: 70px;
}
.padT80 {
    padding-top: 80px;
}
.padT90 {
    padding-top: 90px;
}
.padT100 {
    padding-top: 100px;
}
.padB0 {
    padding-bottom: 0px;
}
.padB5 {
    padding-bottom: 5px;
}
.padB10 {
    padding-bottom: 10px;
}
.padB20 {
    padding-bottom: 20px;
}
.padB30 {
    padding-bottom: 30px;
}
.padB40 {
    padding-bottom: 40px;
}
.padB50 {
    padding-bottom: 50px;
}
.padB60 {
    padding-bottom: 60px;
}
.padB70 {
    padding-bottom: 70px;
}
.padB80 {
    padding-bottom: 80px;
}
.padB90 {
    padding-bottom: 90px;
}
.padB100 {
    padding-bottom: 100px;
}
.padL0 {
    padding-left: 0px;
}
.padL5 {
    padding-left: 5px;
}
.padL10 {
    padding-left: 10px;
}
.padL20 {
    padding-left: 20px;
}
.padL30 {
    padding-left: 30px;
}
.padL40 {
    padding-left: 40px;
}
.padL50 {
    padding-left: 50px;
}
.padL60 {
    padding-left: 60px;
}
.padL70 {
    padding-left: 70px;
}
.padL80 {
    padding-left: 80px;
}
.padL90 {
    padding-left: 90px;
}
.padL100 {
    padding-left: 100px;
}
.padLR0 {
    padding-left: 0px;
}
.padLR5 {
    padding-left: 5px;
}
.padLR10 {
    padding-left: 10px;
}
.padLR20 {
    padding-left: 20px;
}
.padLR30 {
    padding-left: 30px;
}
.padLR40 {
    padding-left: 40px;
}
.padLR50 {
    padding-left: 50px;
}
.padLR60 {
    padding-left: 60px;
}
.padLR70 {
    padding-left: 70px;
}
.padLR80 {
    padding-left: 80px;
}
.padLR90 {
    padding-left: 90px;
}
.padLR100 {
    padding-left: 100px;
}
.padR0 {
    padding-right: 0px;
}
.padR5 {
    padding-right: 5px;
}
.padR10 {
    padding-right: 10px;
}
.padR20 {
    padding-right: 20px;
}
.padR30 {
    padding-right: 30px;
}
.padR40 {
    padding-right: 40px;
}
.padR50 {
    padding-right: 50px;
}
.padR60 {
    padding-right: 60px;
}
.padR70 {
    padding-right: 70px;
}
.padR80 {
    padding-right: 80px;
}
.padR90 {
    padding-right: 90px;
}
.padR100 {
    padding-right: 100px;
}
/* 3.6 Margin Style */

.mar0 {
    margin: 0px;
}
.mar5 {
    margin: 5px;
}
.mar10 {
    margin: 10px;
}
.mar15 {
    margin: 15px;
}
.mar20 {
    margin: 20px;
}
.mar30 {
    margin: 30px;
}
.mar40 {
    margin: 40px;
}
.mar50 {
    margin: 50px;
}
.mar60 {
    margin: 60px;
}
.mar70 {
    margin: 70px;
}
.mar80 {
    margin: 80px;
}
.mar90 {
    margin: 90px;
}
.mar100 {
    margin: 100px;
}
.marT0 {
    margin-top: 0px;
}
.marT5 {
    margin-top: 5px;
}
.marT10 {
    margin-top: 10px;
}
.marT15 {
    margin-top: 15px;
}
.marT20 {
    margin-top: 20px;
}
.marT30 {
    margin-top: 30px;
}
.marT40 {
    margin-top: 40px;
}
.marT50 {
    margin-top: 50px;
}
.marT60 {
    margin-top: 60px;
}
.marT70 {
    margin-top: 70px;
}
.marT80 {
    margin-top: 80px;
}
.marT90 {
    margin-top: 90px;
}
.marT100 {
    margin-top: 100px;
}
.marB0 {
    margin-bottom: 0px;
}
.marB5 {
    margin-bottom: 5px;
}
.marB15 {
    margin-bottom: 15px;
}
.marB10 {
    margin-bottom: 10px;
}
.marB20 {
    margin-bottom: 20px;
}
.marB30 {
    margin-bottom: 30px;
}
.marB40 {
    margin-bottom: 40px;
}
.marB50 {
    margin-bottom: 50px;
}
.marB60 {
    margin-bottom: 60px;
}
.marB70 {
    margin-bottom: 70px;
}
.marB80 {
    margin-bottom: 80px;
}
.marB90 {
    margin-bottom: 90px;
}
.marB100 {
    margin-bottom: 100px;
}
.marL0 {
    margin-left: 0px;
}
.marL5 {
    margin-left: 5px;
}
.marL10 {
    margin-left: 10px;
}
.marL20 {
    margin-left: 20px;
}
.marL30 {
    margin-left: 30px;
}
.marL40 {
    margin-left: 40px;
}
.marL50 {
    margin-left: 50px;
}
.marL60 {
    margin-left: 60px;
}
.marL70 {
    margin-left: 70px;
}
.marL80 {
    margin-left: 80px;
}
.marL90 {
    margin-left: 90px;
}
.marL100 {
    margin-left: 100px;
}
.marR0 {
    margin-right: 0px;
}
.marR5 {
    margin-right: 5px;
}
.marR10 {
    margin-right: 10px;
}
.marR20 {
    margin-right: 20px;
}
.marR30 {
    margin-right: 30px;
}
.marR40 {
    margin-right: 40px;
}
.marR50 {
    margin-right: 50px;
}
.marR60 {
    margin-right: 60px;
}
.marR70 {
    margin-right: 70px;
}
.marR80 {
    margin-right: 80px;
}
.marR90 {
    margin-right: 90px;
}
.marR100 {
    margin-right: 100px;
}
/*
==========================
4 Social Icon
==========================
*/

ul.social-icon {
    float: left;
    width: 100%;
    position: relative;
    padding-bottom: 20px;
}
.social-icon li {
    display: inline-block;
    background: #fff;
    float: left;
    text-align: center;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
}
.social-icon li a {
    color: #333333;
    width: 50px;
    height: 50px;
    padding: 14px 0;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
}
.social-icon li:hover a {
    color: #fff;
}
/*
==========================
5 Header Style
==========================
*/

#header {
    position: relative;
    width: 100%;
    float: left;
    background-color: var(--menuColor);
}
/* 5.1 Top Header */

.top-bar {
    padding: 10px 0;
    color: var(--headerFontColor);
    background-color: var(--headerColor);
}
.top-bar.theme-bg {
    color: #fff;
}
.top-bar.theme-bg a:hover {
    color: #fff;
}
.top-bar ul li {
    display: inline-block;
    margin: 0 10px;
	color:#333333;
}
.top-bar ul li:first-child {
    margin-left: 0;
}
.top-bar ul li:last-child {
    margin-right: 0;
}
.top-bar ul li a i {
    display: inline-block;
    margin: 0 8px 0 0;
}
.top-bar ul li a i:last-child {
    margin-right: 0;
}
.top-bar .right {
    text-align: right;
}
.top-bar .left {
    text-align: left;
}
/* 5.2 Navbar */

a.navbar-brand {
    padding: 27px 0;
}
.navbar-nav {
    padding-right: 36px;
}
.navbar-nav>li>a {
    padding: 30px 20px;
    font-family: var(--fontFamily);
}
.navbar-default .navbar-nav>li>a {
    color: var(--menuFontColor);
    background-color: transparent;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 16px;
    letter-spacing: 1px;
	text-transform: uppercase;
}
body.dark .navbar-default .navbar-nav>li>a {
    color: #fff;
}

.navbar-default {
    background-color: transparent;
}
div#main-menu {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
}
/*
==========================
6 Banner & Slider
==========================
*/
.banner-section {
    float: left;
    background-image: url('../img/all/main-banner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    position: relative;
}
.banner-section img{
    position: absolute;
    height: 100%;
    object-fit: cover;
    background-color: #000;
    opacity: 0.8;
    
}
.banner-section:before {
    background-color: #000;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
	opacity:0.8;
}
#banner-typing {
    position: absolute;
    top: 50%;
    color: #fff;
    font-size: 36px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: -80px;
    width: 100%;
    text-align: center;
}
.slide-caption {
    float: left;
    width: 100%;
    position: relative;
    height: 100vh;
}
.slide-content {
    width: 100%;
    color: #fff;
}
.slide-content h2 {
    color: #fff;
    font-weight: 600;
}
.slide-content h1 {
    font-size: 60px;
    padding-bottom: 20px;
    color: #fff;
    font-weight: bold;
}
.slide-content a {
    background: #333333;
    border: #333333;
    color: #fff;
}
.slide-content .itg-button {
    margin-right: 15px;
}
.slide-content .itg-button:last-child {
    margin-right: 0;
}
.slide-content {
    width: 100%;
    color: #fff;
    position: absolute;
    top: 50%;
    margin-top: -106.5px;
    text-align: center;
}
.slide-statue figure {
    float: left;
}
.slide-statue {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 400px;
}
/*
===================================
7 Theme Heading
===================================
*/

.template-heading {
    width: 100%;
    float: left;
    text-align: center;
    margin-bottom: 60px;
    position: relative;
}
.template-heading h3 {
    position: relative;
    color: #000;
    font-weight: bold;
}
/*
===================================
8 Services Style
===================================
*/

#services {
    background-color: #f2f2f2;
}
body.dark #services {
    background-color: #131212;
}
.service-box {
    float: left;
    width: 100%;
    position: relative;
    padding: 40px 30px;
    background-color: #fff;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
    background-size: cover;
    background-repeat: no-repeat;
}
body.dark .service-box:before {
    background-color: #1b1b1b;
}
.service-box:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}
.service-icon {
    width: 75px;
    height: 75px;
    background-color: #f2f2f2;
    border-radius: 100%;
    text-align: center;
    overflow: hidden;
    font-size: 35px;
    display: flex;
    padding: 20px 0;
    margin-bottom: 20px;
    color: #000;
    position: relative;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
    align-items: center;
    justify-content: center;
}
.service-box h4 {
    line-height: 1em;
    font-weight: bold;
    position: relative;
	-webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
}
.service-box:hover h3 {
    color: #fff;
}
.service-content {
    position: relative;
}
.service-box p {
    margin-bottom: 0;
    color: #333333;
    padding-top: 15px;
}
.service-box:hover .service-icon {
    color: #fff;
}
/*
===================================
9 About Us
===================================
*/

.about-us-section {
    position: relative;
    width: 100%;
    float: left;
}
.about-content {
    position: relative;
    width: 100%;
    float: left;
    padding: 42px 0;
}
.about-list {
    width: 100%;
    float: left;
    position: relative;
}
.about-list li {
    width: 100%;
    float: left;
    margin-bottom: 20px;
}
.about-list li:last-child {
    margin-bottom: 0;
}
.about-list li p {
    margin-bottom: 0px;
}
.about-list .about_box {
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    width: 75px;
    height: 75px;
    border: 1px solid #808080;
    font-size: 30px;
    text-align: center;
    padding: 22px 0;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
}
body.dark .about-list .about_box {
    border: 1px solid #ccc;
    color: #ccc;
}
.about-list li:hover .about_box {
    color: #fff;
}
.about-list .about_box_content {
    display: inline-block;
    float: right;
    width: 80%;
}
.about_box_content h4 {
    font-size: 16px;
}
.about-content p {
    margin-bottom: 20px;
}
/*
=====================================
10 Full Banner Section
=====================================
*/

.full_section {
    float: left;
    width: 100%;
    position: relative;
    padding: 100px 0;
}
.full_section img{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    background-color: #000;
    opacity: 0.8;
}
.full_section_content {
    float: left;
    width: 100%;
    position: relative;
    text-align: center;
}
.full_section_content p {
    color: #fff;
    font-size: 30px;
}
.full_section_content h2 {
    font-size: 40px;
    color: #fff;
    margin-bottom: 20px;
}
.full_section_content a:hover {
    background: #333333;
    border-color: #333333;
}
/*
===================================
11 Team Section
===================================
*/

.team_slider {
    float: left;
    width: 100%;
    position: relative;
}
.team-box {
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 30px;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
}
.bg-color {
    background-color: #f2f2f2;
}
.team-box .caption {
    width: 100%;
    position: relative;
    float: left;
    padding: 20px 0;
    text-align: center;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
}
.team-box .caption span {
    display: inline-block;
}
.team-box .caption p {
    margin-bottom: 0;
    padding-top: 10px;
}
.team-box figure {
    position: relative;
    width: 100%;
    float: left;
    overflow: hidden;
}
.team-box figure img {
    width: 100%;
}
.team-box figure:before {
    position: absolute;
    content: "";
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    opacity: 0;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
}
.team-box figcaption .content {
    position: absolute;
    bottom: -70px;
    left: 50%;
    margin-left: -75px;
}
.team-box:hover figure:before {
    opacity: 1;
}
.content {
    opacity: 0;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
}
.team-box:hover .content {
    opacity: 1;
}
.team-box:hover {
    box-shadow: 0px 7px 14px 3px rgba(0, 0, 0, 0.175);
}
.team-box:hover figcaption .content {
    bottom: 0px;
}
.team-box:hover .caption {
    background: #fff;
}
#our_team .owl-theme .owl-nav {
    position: absolute;
    bottom: 50%;
    float: none;
    display: inline-block;
    width: 100%;
    margin: 0;
}
#our_team .owl-prev {
    position: absolute;
    left: -50px;
    margin-top: -25px;
}
#our_team .owl-next {
    position: absolute;
    right: -50px;
    margin-top: -25px;
}
/*
===================================
12 How Help
===================================
*/

.why_choose_us {
    float: left;
    width: 100%;
    position: relative;
    padding: 41px 0;
}
.why_choose_us_img {
    float: left;
    width: 100%;
}
.why_choose_us ul {
    float: left;
    width: 100%;
}
.why_choose_us_img img {
    width: 100%;
}
.why_choose_us ul li {
    margin-bottom: 30px;
    float: left;
    width: 100%;
}
.why_choose_us ul li:before {
    content: "";
    width: 4px;
    position: absolute;
    height: 83px;
}
.web_design {
    float: left;
    width: 100%;
    position: relative;
    padding-left: 15px;
}
.why_choose_us ul li h4 {
    margin-bottom: 5px;
}
.why_choose_us ul li:last-child {
    margin-bottom: 0;
}
.why_choose_us p {
    margin-bottom: 0;
}
.why_choose_us h4 span {
    font-weight: 600;
    margin-right: 5px;
}
/*
===================================
13 Testimonial Section
===================================
*/

.testimonial-slider {
    float: left;
    position: relative;
    width: 100%;
}
.clients {
    width: 100%;
    float: left;
    position: relative;
    padding-bottom: 50px;
    background: -webkit-linear-gradient(#fff, #f6f6f8);
    background: -o-linear-gradient(#fff, #f6f6f8);
    background: -moz-linear-gradient(#fff, #f6f6f8);
    background: linear-gradient(#fff, #f6f6f8);
}
.client-img-designation {
    width: 100%;
    float: left;
    margin-bottom: 20px;
    text-align: center;
}
.clients .client-img {
    max-width: 80px;
    border-radius: 100%;
    overflow: hidden;
    border: 2px solid;
}
.client-content {
    float: left;
    width: 100%;
    text-align: center;
    position: relative;
}
.client-content h4 {
    margin-bottom: 20px;
}
.client-content p {
    margin-bottom: 0;
    max-width: 63%;
    margin: 0 auto;
}
.client-content span {
    float: left;
    width: 100%;
    margin-top: 20px;
}
/*
===================
14 Gallery Section
======================
*/

.gallery_image {
    float: left;
    width: 100%;
    position: relative;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
}
.gallery_image figure {
    width: 100%;
    float: left;
    position: relative;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
}
.gallery_image figure img {
    width: 100%;
}
.gallery_image figure:before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    content: "";
    opacity: 0;
}
.gallery_image figcaption {
    top: 50%;
    position: absolute;
    text-align: center;
    display: inline-block;
    margin-top: -16px;
    left: 50%;
    margin-left: -13px;
}
.gallery_image figcaption span {
    color: #fff;
    font-size: 35px;
    opacity: 0;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
    cursor: pointer;
}
.gallery_image:hover figcaption span,
.gallery_image:hover figure:before {
    opacity: 1;
}
/*
===================================
15 Facts Section
===================================
*/

.our-facts {
    background-color: #333333;
}
.facts {
    float: left;
    position: relative;
    text-align: center;
    width: 100%;
    padding: 100px 0;
    background-color: #333333;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
}
.facts::before {
    content: "";
    position: absolute;
    top: -12px;
    left: 0;
    opacity: 0;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
    background: red;
    bottom: -12px;
    right: 0;
}
.facts:hover {
    box-shadow: 0px 16px 3px 0px rgba(0, 0, 0, 0.175);
}
.facts:hover::before {
    opacity: 1;
}
.fact-icon {
    margin: 10px 0;
}
.facts h2 {
    font-size: 40px;
    color: #fff;
}
.facts h3 {
    font-size: 30px;
    color: #fff;
}
/*
===================================
16 Pricing Plane
===================================
*/

.pricing-detail {
    position: relative;
    width: 100%;
    float: left;
    background-color: #f2f2f2;
    padding: 40px 25px;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
}
.pricing-head {
    position: relative;
    background-color: #fff;
    text-align: center;
    height: 135px;
    width: 135px;
    border-radius: 100%;
    padding: 37px 0px;
    left: 0;
    margin-bottom: 15px;
    margin: auto;
}
.pricing-head span {
    font-size: 13px;
    font-weight: 700;
	color:#333333;
	-webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
}
.pricing-detail p {
    font-size: 14px;
    text-align: center;
    margin: 25px 0 15px 0;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
}
.pricing-head h2 {
    font-size: 40px;
    font-weight: 800;
    line-height: 1em;
}
.pricing-detail h4 {
    text-transform: capitalize;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
}
.pricing-detail ul li {
    font-size: 14px;
    text-align: center;
    margin-bottom: 30px;
    text-transform: capitalize;
    font-weight: 500;
    letter-spacing: 1px;
	color:#333333;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
}
.pricing-detail span{
    background-color: #333333;
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
    letter-spacing: 1px;
    text-align: center;
    padding: 10px 30px;
    float: left;
    margin: 0 auto;
    width: 100%;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
}
.error-sec {
    position: relative;
    float: left;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}
.error-content {
    position: relative;
    float: left;
    width: 100%;
    padding: 200px 134px;
}
.error-content h3 {
    font-size: 26px;
    text-align: center;
}
.error-content figure img {
    margin: 30px 0;
}
.error-content p {
    text-align: center;
    text-transform: capitalize;
}
.error-content figure {
    width: 100%;
    text-align: center;
}
figure.error-overly:after {
    content: '';
    position: absolute;
    background: rgba(255, 255, 255, 0.68);
    top: 0;
    left: 0;
    right: 0;
    opacity: 0.3;
    bottom: 0;
}
figure.error-overly img {
    width: 100%;
}
.pricing-detail:hover {
    background: #333333;
}
.pricing-detail:hover .pricing-head h2,
.pricing-detail:hover .pricing-head span,
.pricing-detail:hover p,
.pricing-detail:hover h4,
.pricing-detail:hover ul li,
.pricing-detail:hover ul li a,
.pricing-detail:hover span {
    color: #fff;
}
/*
===================================
17 Location Map
===================================
*/

.location_map {
    float: left;
    width: 100%;
    position: relative;
}
.mapael .map {
    position: relative;
}
.mapael .mapTooltip {
    position: absolute;
    background-color: #fff;
    moz-opacity: 1;
    opacity: 1;
    filter: alpha(opacity=100);
    border-radius: 0;
    padding: 3px 20px;
    z-index: 1000;
    display: none;
}
path.area {
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
}
.location_details {
    width: 100%;
    float: left;
    position: relative;
}
.location_details h3 {
    margin-bottom: 10px;
    font-size: 22px;
}
.location_details p {
    color: #333333;
    margin-bottom: 20px;
}
/*
===================================
18 Our Blog
===================================
*/

.blog {
    float: left;
    width: 100%;
    position: relative;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
}
.blog-caption {
    width: 100%;
    float: left;
    position: relative;
}
body.dark .blog .caption {
    background-color: #131212;
}
body.dark .blog:hover .caption {
    box-shadow: 0 6px 12px rgba(225, 225, 225, 0.1);
}
.blog:hover .caption {
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}
.blog-meta {
    padding: 10px 20px;
    width: 100%;
    float: left;
    background: #333333;
    ;
}
body.dark .blog-meta a {
    color: #fff;
}
.blog-meta a {
    color: #fff;
    font-size: 14px;
    margin: 0 10px;
}
.blog-meta a:first-child {
    margin-left: 0;
}
.blog-detail {
    float: left;
    width: 100%;
    padding: 20px;
}
.blog figure {
    float: left;
    width: 100%;
    position: relative;
}
.blog figure img {
    width: 100%;
}
.blog figure:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
}
.blog:hover figcaption span,
.blog:hover figure:after {
    opacity: 1;
}
.blog-detail h4 {
    margin-bottom: 5px;
}
.blog-detail p {
    margin-bottom: 0px;
}
.blog figcaption span {
    color: #fff;
    padding: 13px 18px;
    text-align: center;
    position: absolute;
    float: left;
    bottom: 0;
    left: 50%;
    margin-left: -25px;
    margin-bottom: -25.5px;
    z-index: 1;
    opacity: 0;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
}
.blog figcaption span a:hover {
    color: #fff;
}
.blog:hover figcaption span {
    bottom: 50%;
}
.blog:hover {
    box-shadow: 5px 7px 14px 3px rgba(0, 0, 0, 0.175);
}
/*
=================================
19 Page Style
=================================
*/
/*====19.1 Owl nav Style===*/

.carousel-style-1 {
    position: relative;
}
.carousel-style-1 figure {
    margin-bottom: 1px;
}
.carousel-style-1.owl-theme .owl-nav .owl-prev {
    margin-left: -6%;
}
.carousel-style-1.owl-theme .owl-nav .owl-next {
    margin-right: -6%;
}
.owl-theme .owl-nav {
    position: absolute;
    bottom: 7px;
    margin-top: 0;
    font-size: 30px;
    /*background-color: #f2f2f2;*/
}
body.dark .owl-theme .owl-nav .owl-prev,
body.dark .owl-theme .owl-nav .owl-next {
    background-color: #131212;
}
.owl-theme .owl-nav .owl-prev,
.owl-theme .owl-nav .owl-next {
    border-radius: 0;
    line-height: 40px;
    font-size: 30px;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
    width: 50px;
    height: 50px;
    background-color: #f2f2f2;
    border: 0;
    text-align: center;
    padding: 3px 0;
    border-radius: 0;
    color: #000;
}
.owl-theme .owl-nav .owl-prev:hover,
.owl-theme .owl-nav .owl-next:hover {
    color: #fff !important;
}
.owl-theme .owl-nav .owl-next,
.owl-theme .owl-nav .owl-prev {
    float: none;
    margin: 0;
    padding: 5px 0;
}
.owl-dots {
    position: relative;
    width: 100%;
    padding-top: 20px;
}
.owl-theme .owl-dots .owl-dot span {
    margin: 0;
}
.owl-theme .owl-dots .owl-dot span {
    margin: 0 6px;
    height: 15px;
    width: 15px;
    background-color: transparent;
    border: 1px solid;
}
/*
==========================
19.2 Preloader Style
==========================
*/

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 9999;
}
.s2 {
    position: absolute;
    height: 100px;
    width: 100px;
    background-color: transparent;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
}
.s1 {
    position: absolute;
    height: 100px;
    width: 100px;
    top: 50vh;
    left: 50%;
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: transparent;
}
.bigcon {
    position: absolute;
    height: 95px;
    width: 95px;
    top: 50vh;
    left: 50%;
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: transparent;
    animation: bigcon 2s infinite linear;
    animation-delay: 0.25s;
}
.b {
    border-radius: 50%;
    position: absolute;
}
.s {
    width: 25px;
    height: 25px;
    animation: small 2s infinite ease;
    box-shadow: 0px 2px rgba(0, 0, 0, 0.3);
    background-color: #46b9ff;
}
.s:nth-child(1) {
    top: 0%;
    left: 0%;
}
.s:nth-child(2) {
    top: 0%;
    right: 0%;
}
.s:nth-child(3) {
    right: 0%;
    bottom: 0%;
}
.s:nth-child(4) {
    bottom: 0%;
    left: 0%;
}
.big {
    width: 20px;
    height: 20px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgb(130, 180, 64), 0px 0px 20px rgb(130, 180, 64), 0px 0px 30px rgb(130, 180, 64), 0px 0px 50px rgb(130, 180, 64), 0px 0px 60px rgb(130, 180, 64);
    z-index: 1;
    background-color: rgb(130, 180, 64);
    animation: bigball 1s infinite linear;
}
.sb1 {
    animation-delay: -1.75s;
}
.sb6 {
    animation-delay: -1.5s;
}
.sb2 {
    animation-delay: -1.25s;
}
.sb7 {
    animation-delay: -1s;
}
.sb3 {
    animation-delay: -0.75s;
}
.sb8 {
    animation-delay: -0.5s;
}
.sb4 {
    animation-delay: -0.25s;
}
.sb5 {
    animation-delay: -0s;
}
@keyframes bigcon {
    0% {
        transform-origin: center;
        transform: translate(-50%, -50%) rotate(45deg);
    }
    100% {
        transform-origin: center;
        transform: translate(-50%, -50%) rotate(405deg);
    }
}
@keyframes small {
    0% {
        transform: scale(1);
        background-color: rgb(130, 180, 64);
    }
    10% {
        transform: scale(1.3);
        background-color: rgb(130, 180, 64);
    }
    15% {
        transform: scale(1);
    }
    25% {
        transform: scale(1);
        background-color: rgb(130, 180, 64);
    }
    100% {
        transform: scale(1);
        background-color: rgb(130, 180, 64);
    }
}
/*
==================
19.3 Search Style
==================
*/

.serach-footer {
    position: absolute;
    right: 0;
    margin: 27px 15px 27px 0;
}
a.serach-footer i {
    color: #000;
}
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.87);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
  z-index:3;
}
.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
    border-radius: 5px;
    position: relative;
    transition: all 5s ease-in-out;
    top: 40%;
}
.search-section{
	float: left;
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -84.5px;
}
.search-header {
	padding:15px;
    border-bottom: 1px solid #fff;
}
.search-header h4 {
  margin-top: 0;
  color: #fff;
}
.popup .close {
	position: absolute;
    top: 15px;
    right: 24px;
    font-size: 25px;
    opacity: 1;
	color:#fff;
}
.popup .content-search {
 padding: 35px 15px;
}
.search-wrap {
    background-color: #fff;
    background-image: none;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0px 0px rgba(0,0,0,.075);
    box-shadow: inset 0 0px 0px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    width: 100% !important;
}
.search-btn:active,
.btn-default:active:hover,
.search-btn:hover, .search-btn:focus {
    background: #333333;
    color: #fff !important;
	outline:none;
}
.search-btn {
    background: #333333;
    width: 100%;
    border-radius: 0px 10px 10px 0px;
    color: #fff !important;
    height: 40px;
    border: 0px solid;
    font-weight: 600;
    font-size: 14px;
}
span.serach-bottom {
    left: 20px;
    position: absolute;
    top: 8px;
}
.top_search input {
    padding-left:45px;
}
.top_search input:focus{
	outline:none;
	border-color:#fff;
	box-shadow: none;
}
/*
==========================
20 Contact Form
==========================
*/

.template-form {
    float: left;
    width: 100%;
    position: relative;
    overflow-x: hidden;
}
.template-form input,
.template-form textarea {
    margin-bottom: 30px;
}
body.dark .template-form input,
body.dark .template-form textarea {
    background-color: #131212;
    color: #fff;
}
.template-form .itg-button,
body.dark .template-form.itg-button {
    margin-bottom: 0px;
}
.template-form .itg-button {
    border: none;
    padding: 15px 30px;
    text-transform: uppercase;
    outline: none;
}
/*
===================================
21 Footer Style
===================================
*/

footer {
    float: left;
    width: 100%;
    position: relative;
}
.top-footer {
    background-color: var(--footerColor);
    float: left;
    width: 100%;
    position: relative;
    color: #333333;
}
footer:before {
    content: "";
    position: absolute;
    width: 41.5%;
    z-index: 1;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
}
.top-footer .widget {
    width: 100%;
    float: left;
    position: relative;
    z-index: 1;
}
.top-footer .widget .logo {
    float: left;
    margin-bottom: 30px;
}
.top-footer .widget p {
    color: #fff;
    line-height: 1.8em;
    margin-bottom: 0;
    clear: both;
}
.top-footer .widget .social-icon {
    text-align: left;
    margin-top: 30px;
}
ul.social-icon-footer {
    float: left;
    position: relative;
    margin: 30px 0 20px;
}
ul.social-icon-footer li {
    display: inline-block;
    background: #fff;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 50%;
    padding: 3px 0;
    margin-right: 10px;
    -webkit-transition: all 0.45s ease-in-out;
    -moz-transition: all 0.45s ease-in-out;
    -o-transition: all 0.45s ease-in-out;
    -ms-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
}
ul.social-icon-footer li:last-child {
    margin-right: 0;
}
.top-footer .widget h4 {
    color: #fff;
    margin-bottom: 30px;
}
.information ul li {
    color: #fff;
}
.top-footer .widget ul li {
    margin-bottom: 10px;
    font-size: 14px;
}
.top-footer .widget ul li:last-child {
    margin-bottom: 0;
}
.top-footer .widget ul.gallery {
    float: left;
    width: 100%;
}
.top-footer .widget ul.gallery li {
    float: left;
    margin-bottom: 10px;
    margin-right: 10px;
    max-width: 64px;
}
.top-footer .widget ul.gallery li:last-child {
    margin-right: 0;
}
ul.contact-footer {
    float: left;
    width: 100%;
    position: relative;
}
ul.contact-footer li {
    width: 100%;
    color: #fff;
}
ul.contact-footer li:last-child {
    margin-bottom: 0;
}
ul.contact-footer li i {
    margin-right: 15px;
}

.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next{
    background: #f2f2f2;
}