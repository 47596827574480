:root {
    --mainColor: #81b441;
    --fontFamily: 'Poppins', sans-serif;
    --menuColor: #ffff;
    --headerColor: #fff;
    --footerColor: #0f1221;
    --headerFontColor: #333333;
    --menuFontColor: #000;
    --footerColor: #333333;
}

@import "./css/bootstrap.min";
@import "./plugin/font-awesome/css/font-awesome.min";
@import "./css/style";
@import "./css/responsive";
@import "./css/color";

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover,
.nav .open > a:focus,
.nav .open > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
ul.nav.navbar-nav li a:hover {
    background-color: var(--mainColor);
    color: #fff;
}
.slide-content h1 {
    span{
        color: var(--mainColor);
    }
}
.popup-visible{
    visibility: visible;
    opacity: 1;
}
.success-request{
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transform: translateX(-100%);
    transition: .3s;

    .request-span{
        font-size: 60px;
        color: var(--mainColor);
    }
}

.success-request-visible{
    transform: translateX(0);
}

.error{
    border: 1px solid red;
}

.modal.show{
    opacity: 1 !important;
}

.modal-backdrop.show{
    opacity: 0.5 ;
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1.75rem * 2);
}

@media (min-width: 576px){
    .modal {
        --bs-modal-margin: 1.75rem;
        --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    }
}

@media (min-width: 768px){
.modal-dialog {
    margin: 0 auto;
}
}
.modal-header{
    display: flex;
}
.btn-close{
    font-size: 20px;
    margin-left: auto;
    cursor: pointer;
}

.modal-new{
    width: 500px;
    padding: 25px;
    position: relative;
    overflow-x: hidden;

    p{
        text-align: center;
    }

    input{
        font-size: 16px;
    }
    form{
        display: flex;
        flex-direction: column;
    }
    .itg-button{
        padding: 10px 20px;
        margin-left: auto;
    }

}

.mb-3{
    margin-bottom: 12px;
}

.justify-content-center{
    display: flex;
    justify-content: center;
}

.isLoading {
    pointer-events: none;
    //opacity: .5;
    position: relative;
    min-height: 100px;
  
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -20px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 3px solid var(--mainColor);
      border-left-color: transparent;
      animation: rotate .5s infinite linear;
      z-index: 99;
    }
  
    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
  }

  @media(min-width:768px){
    .justify-content-between{
        display: flex;
        justify-content: space-between;
    }
    .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand{
        margin-left: 0;
    }
  }

